import React from "react";
import { Link } from "@StarberryUtils";
import ScrollAnimation from "react-animate-on-scroll";
import { Button, Form, Container, FormControl, Row, InputGroup, Col, Breadcrumb} from "react-bootstrap";
import "./FormPage.scss";

const FormPage = () => {
    
    
    return (
        <React.Fragment>
            <section className="multi-form-section">
                <Container>
                    
                    <Row className="d-lg-none">
                        <Col lg={12}>
                            <Button type="button" className="button-back btn-text-link"  variant="link"><i className="icon-back"></i> Go Back</Button>
                        </Col>
                    </Row>
                    <div className="form-container">
                    <Row>
                        <Col lg={12}>
                            <ScrollAnimation animateOnce={true} animateIn="fadeInUp"><h1>So you need a valuation. Let’s start with your name please.</h1></ScrollAnimation>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                        <div className="form">
                          
                        <Form>
                        <Form.Group controlId="formBasicEmail">
                        <ScrollAnimation animateOnce={true} animateIn="fadeIn"> 
                            <Form.Label>Email Address</Form.Label>
                            <Form.Control type="email" placeholder="chrisr@starberry.tv" />
                        </ScrollAnimation>
                        </Form.Group>

                        <Form.Group controlId="formBasicTelephone">
                        <ScrollAnimation animateOnce={true} animateIn="fadeIn"> 
                            <Form.Label>Telephone Number</Form.Label>
                            <Form.Control type="tel" placeholder="07891219716"/>
                        </ScrollAnimation>
                        </Form.Group>
                        <Form.Group controlId="formBasicTelephone">
                        <ScrollAnimation animateOnce={true} animateIn="fadeInUp"><Form.Label>Do you want to Sell or Let your property</Form.Label></ScrollAnimation>
                        <ScrollAnimation animateOnce={true} animateIn="fadeIn">
                            <div className="form-button-groups">
                            <Button type="button" variant="outline" className="outline-orange">I’d like to Sell</Button>
                            <Button type="button" variant="outline" className="outline-primary">I’d like to Let</Button>
                            </div>
                        </ScrollAnimation>    
                        </Form.Group>
                        <Form.Group controlId="formBasicPreferredDate">
                        <ScrollAnimation animateOnce={true} animateIn="fadeIn">
                            <Form.Label>Preferred Date</Form.Label>
                            <div className="input-box-addon-group">
                            <span className="input-addon-box">
                                <i className="icon-date"></i>
                            </span>
                            <Form.Control type="text" placeholder="Saturday 26, February 2021" />
                            </div>
                        </ScrollAnimation>    
                        </Form.Group>
                        <Form.Group controlId="formBasicTelephone">
                        <ScrollAnimation animateOnce={true} animateIn="fadeIn">
                            <Form.Label>Preferred Time</Form.Label>
                            <div className="form-button-groups grid-3">
                                <Button type="button" variant="outline" className="outline-primary">Morning</Button>
                                <Button type="button" variant="outline" className="outline-primary">Afternoon</Button>
                                <Button type="button" variant="outline" className="outline-primary">Evening</Button>
                            </div>
                        </ScrollAnimation>    
                        </Form.Group>
                        <ScrollAnimation animateOnce={true} animateIn="fadeIn">
                            <Button variant="primary" className="button-submit" type="submit">Next Step</Button>
                        </ScrollAnimation>
                        </Form>
                        
                        </div>
                        </Col>
                    </Row>
                    </div>
                </Container>
            </section>
        </React.Fragment>
    )
}

export default FormPage;