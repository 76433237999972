import React from "react";
import { Link } from "@StarberryUtils";
import ScrollAnimation from "react-animate-on-scroll";
import { Button, Form, Container, FormControl, Row, InputGroup, Col, Breadcrumb} from "react-bootstrap";
import "./ContactSection.scss";
import { useStaticQuery, graphql } from "gatsby";
import ReactMarkdown from "react-markdown"
import GetURL from '@Components/common/site/get-url';
const ContactSection = () => {
    const dataglobal = useStaticQuery(graphql`
  query{
  glstrapi {
    globalConfig(publicationState: LIVE) {
        Multiform_Contact {
          Content
          Cta_Label
          Title
          Icon {
            alternativeText
            url
          }
          Cta_Url {
            Alias
          }
        }
      }
  }
  }
  `);    
    
    return (
        <React.Fragment>
            <section className="multi-form-section">
            <Container>
                <div className="form-container">
                <Row>
                  <Col lg={12}>
                    <ScrollAnimation animateOnce={true} animateIn="fadeInUp"><h1>All done. We’ll be in touch to confirm your booking.</h1></ScrollAnimation>
                  </Col>
                </Row>
                </div>
                </Container>
            <section className="contact-section-form-page">
                <Container>
                    <div className="container-contact">
                    <Row>
                        
                    {dataglobal && dataglobal?.glstrapi?.globalConfig?.Multiform_Contact?.length > 0 ? dataglobal?.glstrapi?.globalConfig?.Multiform_Contact?.map((item, ind) => {
                          
                          return (
                        <Col md={4}>
                            <div className="contact-block">
                            {/* <ScrollAnimation animateOnce={true} animateIn="fadeIn"> */}
                                <div className="contact-block-icon">
                                    <img src={item.Icon?.url} />
                                </div>
                            {/* </ScrollAnimation>     */}
                                <div className="contact-block-content">
                                {/* <ScrollAnimation animateOnce={true} animateIn="fadeIn"> */}
                                    <h6>{item.Title}</h6>
                                {/* </ScrollAnimation> */}
                                {/* <ScrollAnimation animateOnce={true} animateIn="fadeIn">     */}
                                <ReactMarkdown source={item.Content} allowDangerousHtml />
                                {/* </ScrollAnimation> */}
                                {/* <ScrollAnimation animateOnce={true} animateIn="fadeIn">  */}
                                <GetURL label={item.Cta_Label} class="text-link" alias={item.Cta_Url?.Alias} />   
                                {/* </ScrollAnimation>     */}
                                </div>
                            </div>
                        </Col>
 )
}) : ''}
                        {/* <Col md={4}>
                            <div className="contact-block">
                            <ScrollAnimation animateOnce={true} animateIn="fadeIn">
                                <div className="contact-block-icon">
                                    <i className="icon-user"></i>
                                </div>
                            </ScrollAnimation>    
                                <div className="contact-block-content">
                                <ScrollAnimation animateOnce={true} animateIn="fadeIn">
                                    <h6>Meet our team</h6>
                                </ScrollAnimation>
                                <ScrollAnimation animateOnce={true} animateIn="fadeIn">
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc et diam lorem. </p>
                                </ScrollAnimation>        
                                <ScrollAnimation animateOnce={true} animateIn="fadeIn">
                                    <Link className="text-link" href="#">Meet our team</Link>
                                </ScrollAnimation>    
                                </div>
                            </div>
                        </Col>

                        <Col md={4}>
                            <div className="contact-block">
                            <ScrollAnimation animateOnce={true} animateIn="fadeIn">
                                <div className="contact-block-icon">
                                    <i className="icon-chat"></i>
                                </div>
                            </ScrollAnimation>    
                                <div className="contact-block-content">
                                <ScrollAnimation animateOnce={true} animateIn="fadeIn">
                                    <h6>Still need help?</h6>
                                </ScrollAnimation>
                                <ScrollAnimation animateOnce={true} animateIn="fadeIn">
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc et diam lorem.</p>
                                </ScrollAnimation>
                                <ScrollAnimation animateOnce={true} animateIn="fadeIn">
                                    <Link className="text-link" href="#">Get in touch</Link>
                                </ScrollAnimation>
                                </div>
                            </div>
                        </Col> */}
                    </Row>
                    </div>
                </Container>
            </section>
            </section>
            
        </React.Fragment>
    )
}

export default ContactSection;