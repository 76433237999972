import React from "react";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import BreadcrumbPage from "../../Components/MultiForm/BreadcrumbPage/BreadcrumbPage";
import FormPage from "../../Components/MultiForm/FormPage/FormPage"
import ContactSection from "../../Components/MultiForm/ContactSection/ContactSection";
import "./MultiForm.scss";
const MultiForm = () => {
    return (
        <React.Fragment>
            <div className="header-logo-black form-page-menubar"><Header/></div>
            <FormPage/>
            <ContactSection/>
            <div className="breadcrumb-other-page"><BreadcrumbPage/></div>
            <Footer/>
        </React.Fragment>
    )
}

export default MultiForm